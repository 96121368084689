import { getLogisticsTenant } from '../../application/configuration/login/multiTenantUtils';

type ApplicationTheme = 'rio' | 'scania';

export const getTheme = (): ApplicationTheme => {
    if (import.meta.env.VITE_THEME_OVERRIDE) {
        return import.meta.env.VITE_THEME_OVERRIDE;
    }

    switch (getLogisticsTenant()) {
        case 'scania-cn-sandbox':
        case 'scania-cn-preprod':
        case 'scania-cn':
        case 'scania-cn-dev':
        case 'rio-scm-test':
            return 'scania';
        case 'rio-mahle-demo':
        case 'rio-vw-test':
            return 'rio';
    }
};
