const asBool = (value: string | undefined): boolean => value === 'true';

export interface ConfigState {
    login: {
        authority: string | undefined;
        clientId: string | undefined;
        oauthScope: string[];
        authenticationBackend: 'production' | 'local' | 'msw';
        preventRedirect: boolean;
        redirectUriRioMahleDemo: string | undefined;
        redirectUriRioVwTest: string | undefined;
        redirectUriRioScaniaCnTest: string | undefined;
        redirectUriDev: string | undefined;
        redirectUriPreprod: string | undefined;
        redirectUriSandbox: string | undefined;
        redirectUri: string | undefined;
        silentRedirectUri: string | undefined;
        mockTenant: string | undefined;
        mockLogisticsTenant: string | undefined;
        mockAccountId: string | undefined;
    };
    configCat: {
        apiKey: string;
        pollIntervalSeconds: number;
    };
}

export const config: ConfigState = {
    login: {
        authority: import.meta.env.VITE_LOGIN_AUTHORITY,
        clientId: import.meta.env.VITE_LOGIN_CLIENT_ID,
        oauthScope: ['openid', 'profile', 'email'],
        authenticationBackend: import.meta.env.PROD
            ? 'production'
            : import.meta.env.MODE === 'local-BE'
              ? 'local'
              : 'msw',
        preventRedirect: asBool(import.meta.env.VITE_LOGIN_PREVENT_REDIRECT),
        redirectUriRioMahleDemo: import.meta.env.VITE_LOGIN_REDIRECT_URI_RIO_MAHLE_DEMO,
        redirectUriRioVwTest: import.meta.env.VITE_LOGIN_REDIRECT_URI_RIO_VW_TEST,
        redirectUriRioScaniaCnTest: import.meta.env.VITE_LOGIN_REDIRECT_URI_RIO_SCANIA_CN_TEST,
        redirectUriDev: import.meta.env.VITE_LOGIN_REDIRECT_URI_DEV,
        redirectUriPreprod: import.meta.env.VITE_LOGIN_REDIRECT_URI_PREPROD,
        redirectUriSandbox: import.meta.env.VITE_LOGIN_REDIRECT_URI_SANDBOX,
        redirectUri: import.meta.env.VITE_LOGIN_REDIRECT_URI,
        silentRedirectUri: import.meta.env.VITE_LOGIN_SILENT_REDIRECT_URI,
        mockTenant: import.meta.env.VITE_LOGIN_MOCK_TENANT,
        mockLogisticsTenant: import.meta.env.VITE_LOGIN_MOCK_LOGISTICS_TENANT,
        mockAccountId: import.meta.env.VITE_LOGIN_MOCK_RIO_ACCOUNT_ID,
    },
    configCat: {
        apiKey: import.meta.env.VITE_CONFIGCAT_API_KEY,
        pollIntervalSeconds: parseInt(import.meta.env.VITE_CONFIGCAT_POLL_INTERVAL_SECONDS, 10),
    },
};
